import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";
import LazyLoad from "react-lazyload";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageTypeConsumer } from "../components/context/page-type";

// Components
import { AllDirectorsGalleries } from "../components/directors/all-directors-galleries";
import { Caption } from "../components/text/caption";
import { ShortVideo } from "../components/videos/short-video";
import { VimeoThumbnail } from "../components/images/vimeo-thumbnail";

// Icons
import { RoomSixEventIcon } from "../components/icons/room-six-event-icon";

const Page = styled.div`
  & strong {
    text-align: center;
  }
`;

const IntroTextContainer = styled.div`
  width: 100%;
  max-width: 1160px;
  text-align: center;

  margin: 0 auto;
  padding: 40px 20px 35px 20px;

  & h2,
  & p {
    font-size: 48px;
    line-height: 55px;
    letter-spacing: 0.01em;

    color: rgba(253, 252, 252, 0.8);

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    @media (max-width: 900px) {
      font-size: 26px;
      line-height: 30px;
    }
  }

  & button {
    font-family: "Caslon Doric Web", "Helvetica", "Lucida Grande", sans-serif;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.04em;
    text-transform: uppercase;

    margin: 30px auto 0 auto;
    padding: 5px 10px;

    color: rgba(250, 251, 246, 0.5);
  }
`;

const MediumTextContainer = styled.div`
  width: 100%;
  max-width: 900px;

  margin: 0 auto;
  padding: 60px 20px;

  text-align: center;

  font-size: 36px;
  line-height: 36px;
  letter-spacing: 0.01em;

  color: rgba(253, 252, 252, 0.8);

  @media (max-width: 900px) {
    font-size: 26px;
    line-height: 30px;
  }
`;

const TextContainer = styled.div`
  width: 100%;
  max-width: 680px;

  margin: 0 auto;
  padding: 60px 20px;

  text-align: left;

  font-family: "Caslon Doric Web", "Helvetica", "Lucida Grande", sans-serif;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.1em;

  color: #fcfbfc;

  & .biography-text {
    max-width: 675px;
    margin: 0 auto;

    & p:first-of-type {
      margin-top: 0;
    }

    & p:last-of-type {
      margin-bottom: 0;
    }
  }

  @media (max-width: 1300px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const DownloadButtonContainer = styled.div`
  width: 100%;
  max-width: 680px;

  margin: 0 auto;
  padding: 60px 20px 125px 20px;

  text-align: center;

  font-size: 36px;
  line-height: 36px;
  letter-spacing: 0.01em;

  color: rgba(253, 252, 252, 0.8);

  & .text-container {
    max-width: 675px;
    margin: 0 auto;

    & p:first-of-type {
      margin-top: 0;
    }

    & p:last-of-type {
      margin-bottom: 0;
    }
  }

  @media (max-width: 900px) {
    font-size: 26px;
    line-height: 30px;
  }

  & a {
    font-family: "Caslon Doric Web Medium", "Helvetica", "Lucida Grande",
      sans-serif;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.09em;
    text-transform: uppercase;
    border: 1px solid transparent;

    display: block;

    width: 220px;

    height: 30px;

    border-radius: 1px;

    margin: 30px auto 0 auto;

    color: #ffffff;
    background: #df3f1c;

    transition: 150ms all ease;

    &:hover {
      background: #fff;
      color: #df3f1c;
    }
  }
`;

const TextWithLogoContainer = styled.div`
  & .logo-container {
    max-width: 300px;
    margin: 125px auto 0px;

    @media (max-width: 900px) {
      max-width: 190px;
    }
  }

  & .text-container {
    width: 100%;
    max-width: 680px;

    margin: 0 auto;
    padding: 30px 20px 125px 20px;

    text-align: left;

    font-family: "Caslon Doric Web", "Helvetica", "Lucida Grande", sans-serif;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.1em;

    color: #fcfbfc;

    @media (max-width: 900px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: dense;

  width: 100%;
`;

const EventContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  grid-column-end: ${props => props.column};
  grid-row-end: ${props => props.rowColumn};

  overflow: hidden;

  border: 1px solid #1d1d1b;

  pointer-events: ${props => props.pointerEvents};

  & a {
    display: block;

    width: 100%;
    height: 100%;

    position: relative;
  }

  @media (max-width: 768px) {
    grid-column-end: span 2;
  }
`;

const VideoWrapper = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ${props => props.ratio}%;
  }

  & > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  & img {
    transition: 250ms opacity ease;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &:hover img {
    opacity: ${props => (props.fadeOutImage ? 0 : 1)};
  }

  & .room-six-event-icon {
    position: absolute;
    top: 20px;
    right: 20px;

    z-index: 100;

    width: 35px;
    height: auto;
  }
`;

const LogosContainer = styled.div`
  max-width: 1400px;
  padding: 0 100px 125px 100px;
  margin: 0 auto;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  & .single-logo {
    flex: 20%;
    max-width: 20%;
    padding: 35px 30px;

    @media (max-width: 768px) {
      flex: 33.333%;
      max-width: 33.333%;

      padding: 20px;
    }
  }

  @media (max-width: 1200px) {
    // padding: 0 50px;
    padding: 0 50px 125px 50px;
  }

  @media (max-width: 768px) {
    padding: 0 0 125px 0;
  }
`;

const EventsContent = ({ data, setPageType, location }) => {
  const [currentVideoPlayerId, setCurrentVideoPlayerId] = useState("");
  const [shouldImageFadeOut, setImageFadeOut] = useState(false);
  const [allowPointerEvents, setAllowPointerEvents] = useState("none");
  const [onReady, setOnReady] = useState(false);

  useEffect(() => {
    setAllowPointerEvents(`all`);
  }, [onReady, setAllowPointerEvents]);

  useEffect(() => {
    setPageType("events");
  }, []);

  const content = data.prismicEvents.data.body.map((content, index) => {
    if (content.slice_type === "video") {
      return (
        <AllDirectorsGalleries
          data={content.items}
          location={location}
          videoLocation={`events`}
        />
      );
    }

    if (content.slice_type === "text") {
      return (
        <IntroTextContainer
          className="large-text-container"
          key={`single_large_text_container_${index}`}
        >
          <div
            className="large-text"
            dangerouslySetInnerHTML={{
              __html: content.primary.text.html,
            }}
          />

          <button
            className="read-more-button"
            onClick={() =>
              document.querySelector("#read-more").scrollIntoView({
                behavior: "smooth",
                block: "start",
              })
            }
          >
            Read More
          </button>
        </IntroTextContainer>
      );
    }

    if (content.slice_type === "events") {
      const events = content.items
        .filter(
          content =>
            content !== null &&
            content.case_study.document !== null &&
            content.case_study.document.data !== null &&
            content.case_study.document.data !== undefined
        )
        .map((content, index) => (
          <EventContainer
            key={`director_video_${index}_${content.case_study.document.id}`}
            column={
              content.case_study_size === "Landscape" ? `span 1` : `span 1`
            }
            rowColumn={
              content.case_study_size === "Landscape" ? `span 1` : `span 2`
            }
            className={content.case_study_size}
            onMouseOver={() =>
              setCurrentVideoPlayerId(content.case_study.document.id)
            }
            onMouseLeave={() => setCurrentVideoPlayerId("")}
            pointerEvents={allowPointerEvents}
          >
            <Link
              to={content.case_study.document.url}
              state={{
                location: location,
                // videoLocation: videoLocation,
              }}
            >
              <VideoWrapper
                ratio={
                  content.case_study_size === "Landscape" ? `56.25` : `100`
                }
                fadeOutImage={
                  currentVideoPlayerId === content.case_study.document.id &&
                  shouldImageFadeOut &&
                  content.case_study.document.data.vimeo_mp4_file.url.length > 0
                }
              >
                <div className="content">
                  <LazyLoad height={400} offset={200}>
                    <ShortVideo
                      video={
                        content.case_study.document.data.vimeo_mp4_file.url
                      }
                      width={1920}
                      height={1080}
                      playerId={`all_directors_video_${index}`}
                      setImageFadeOut={shouldImageFadeOut =>
                        setImageFadeOut(shouldImageFadeOut)
                      }
                      preload={index <= 5 ? "metadata" : "none"}
                      isPlaying={
                        currentVideoPlayerId === content.case_study.document.id
                          ? true
                          : false
                      }
                      loadedSeconds={0.5}
                      setOnReady={setOnReady}
                    />
                  </LazyLoad>

                  <VimeoThumbnail
                    width={1920}
                    height={1080}
                    prismicThumbnail={
                      content.case_study.document.data.thumbnail.fluid
                    }
                    embed_url={
                      content.case_study.document.data.vimeo_video !== undefined
                        ? content.case_study.document.data.vimeo_video.embed_url
                        : null
                    }
                  />
                </div>

                {content.room_six_case_study === true && <RoomSixEventIcon />}
              </VideoWrapper>

              <Caption>
                {content.case_study.document.type === `video` ? (
                  <>
                    {content.case_study.document.data.brand.text !== "" ? (
                      <h2>{content.case_study.document.data.brand.text}</h2>
                    ) : (
                      <h2>{content.case_study.document.data.title.text}</h2>
                    )}
                    {content.case_study.document.data.director.uid !== null && (
                      <p>
                        {
                          content.case_study.document.data.director.document
                            .data.title.text
                        }
                      </p>
                    )}
                  </>
                ) : (
                  <h2>{content.case_study.document.data.title.text}</h2>
                )}
              </Caption>
            </Link>
          </EventContainer>
        ));

      return (
        <Container key={`single_events_container_${index}`}>{events}</Container>
      );
    }

    if (content.slice_type === "text_with_download_button") {
      return (
        <DownloadButtonContainer
          className="text-with-download-button-container"
          key={`single_text_with_download_button_container_${index}`}
        >
          <div
            className="text-container"
            dangerouslySetInnerHTML={{
              __html: content.primary.text_with_download.html,
            }}
          />

          <a href={content.primary.download_file.url} target="_blank" download>
            {content.primary.download_button_call_to_action}
          </a>
        </DownloadButtonContainer>
      );
    }

    if (content.slice_type === "bio_text") {
      return (
        <TextWithLogoContainer
          className="text-with-logo-container"
          key={`single_text-with-logo_container_${index}`}
          id="read-more"
        >
          <div className="logo-container">
            {content.primary.logo.fluid !== null && (
              <img
                srcSet={content.primary.logo.fluid.srcSetWebp}
                src={content.primary.logo.fluid.srcWebp}
                alt={content.primary.logo.alt}
                loading="lazy"
              />
            )}
          </div>

          <div
            className="text-container"
            dangerouslySetInnerHTML={{
              __html: content.primary.text_with_logo.html,
            }}
          />
        </TextWithLogoContainer>
      );
    }

    if (content.slice_type === "medium_text") {
      return (
        <MediumTextContainer
          className="medium-text-container"
          key={`single_medium_text_container_${index}`}
        >
          <div
            className="medium-text"
            dangerouslySetInnerHTML={{
              __html: content.primary.text_medium.html,
            }}
          />
        </MediumTextContainer>
      );
    }

    if (content.slice_type === "logos") {
      const logos = content.items.map((logo, index) => (
        <div className="single-logo" key={`single_logo_${index}`}>
          {logo.logo.fluid !== null && (
            <img
              srcSet={logo.logo.fluid.srcSetWebp}
              src={logo.logo.fluid.srcWebp}
              alt={logo.logo.alt}
              loading="lazy"
            />
          )}
        </div>
      ));

      return <LogosContainer>{logos}</LogosContainer>;
    }

    if (content.slice_type === "small_text") {
      return (
        <TextContainer
          className="small-text-container"
          key={`single_small_text_container_${index}`}
        >
          <div
            className="small-text"
            dangerouslySetInnerHTML={{
              __html: content.primary.text_small.html,
            }}
          />
        </TextContainer>
      );
    }
  });

  return (
    <>
      <Helmet
        title={`Events – Park Village`}
        meta={[
          {
            name: "og:title",
            content: `Events – Park Village`,
          },
          {
            name: "twitter:title",
            content: `Events – Park Village`,
          },
        ]}
      />
      <Page>{content}</Page>
    </>
  );
};

const Events = ({ data, location }) => (
  <PageTypeConsumer>
    {({ setPageType }) => (
      <EventsContent
        setPageType={setPageType}
        data={data}
        location={location}
      />
    )}
  </PageTypeConsumer>
);

export default withPreview(Events);

export const query = graphql`
  {
    prismicEvents {
      data {
        body {
          ... on PrismicEventsBodyVideo {
            id
            slice_type
            items {
              video {
                document {
                  ... on PrismicVideo {
                    id
                    uid
                    data {
                      title {
                        text
                      }
                      brand {
                        text
                      }
                      vimeo_video {
                        title
                        description
                        embed_url
                        width
                        height
                      }
                      vimeo_mp4_file {
                        url
                      }
                      director {
                        uid
                        document {
                          ... on PrismicDirector {
                            id
                            data {
                              title {
                                text
                              }
                            }
                          }
                        }
                      }
                      thumbnail {
                        alt
                        url
                        fluid(imgixParams: { w: 2000 }) {
                          srcWebp
                          srcSetWebp
                        }
                      }
                    }
                    uid
                  }
                }
              }
            }
          }
          ... on PrismicEventsBodyText {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicEventsBodyBioText {
            id
            slice_type
            primary {
              logo {
                alt
                fluid {
                  srcWebp
                  srcSetWebp
                }
              }
              text_with_logo {
                html
              }
            }
          }
          ... on PrismicEventsBodyTextWithDownloadButton {
            id
            slice_type
            primary {
              text_with_download {
                html
              }
              download_button_call_to_action
              download_file {
                url
              }
            }
          }
          ... on PrismicEventsBodyEvents {
            id
            slice_type
            items {
              case_study_size
              room_six_case_study
              case_study {
                document {
                  ... on PrismicEvent {
                    id
                    url
                    type
                    data {
                      thumbnail {
                        alt
                        fluid {
                          srcSetWebp
                          srcWebp
                        }
                      }
                      vimeo_mp4_file {
                        url
                      }
                      title {
                        text
                      }
                    }
                  }
                  ... on PrismicVideo {
                    id
                    url
                    type
                    data {
                      title {
                        text
                      }
                      brand {
                        text
                      }
                      vimeo_video {
                        title
                        description
                        embed_url
                        width
                        height
                      }
                      vimeo_mp4_file {
                        url
                      }
                      director {
                        uid
                        document {
                          ... on PrismicDirector {
                            id
                            data {
                              title {
                                text
                              }
                            }
                          }
                        }
                      }
                      thumbnail {
                        alt
                        url
                        fluid(imgixParams: { w: 2000 }) {
                          srcWebp
                          srcSetWebp
                        }
                      }
                    }
                    uid
                  }
                }
              }
            }
          }
          ... on PrismicEventsBodyMediumText {
            id
            slice_type
            primary {
              text_medium {
                html
              }
            }
          }
          ... on PrismicEventsBodySmallText {
            id
            slice_type
            primary {
              text_small {
                html
              }
            }
          }
          ... on PrismicEventsBodyLogos {
            id
            slice_type
            items {
              logo {
                alt
                fluid {
                  srcSetWebp
                  srcWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
