import React, { useContext } from "react";
import Media from "react-media";

// Context
import WindowWidthContext from "../context/window-width";

// Components
import { Gallery } from "../images/gallery";
import { MobileGallery } from "../images/mobile-gallery";

export const AllDirectorsGalleries = ({ data, location, videoLocation }) => {
  const windowWidth = useContext(WindowWidthContext);

  return (
    <>
      <Media
        queries={{ medium: "(min-width: 769px)" }}
        defaultMatches={{ medium: windowWidth === 769 }}
        render={() => (
          <Gallery
            data={data}
            location={location}
            videoLocation={videoLocation}
          />
        )}
      />

      <Media
        queries={{ medium: "(max-width: 768px)" }}
        defaultMatches={{ medium: windowWidth === 768 }}
        render={() => (
          <MobileGallery
            data={data}
            location={location}
            videoLocation={videoLocation}
          />
        )}
      />
    </>
  );
};
