import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const Icon = styled.svg``;

export const RoomSixEventIcon = () => (
  <Icon
    width="67"
    height="82"
    viewBox="0 0 67 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="room-six-event-icon"
  >
    <path
      d="M58.7697 36.2665C54.2947 31.6385 48.2747 28.8172 41.8547 28.3397L42.2577 0.773547L40.7177 0.740241H40.1187C29.9497 0.734578 20.1577 4.59323 12.7257 11.535C5.29466 18.4768 0.77766 27.983 0.0916604 38.1293C-0.59534 48.2755 2.59867 58.3036 9.02767 66.1835C15.4557 74.0633 24.6387 79.2061 34.7157 80.5708C36.2707 80.8745 37.8497 81.0404 39.4337 81.0667H39.8337C45.0217 81.064 50.0947 79.5331 54.4177 76.6651C58.7417 73.7971 62.1247 69.7191 64.1447 64.9402C66.1637 60.1614 66.7317 54.8934 65.7757 49.7939C64.8197 44.6945 62.3837 39.9895 58.7697 36.2665ZM23.0657 70.938C19.9337 67.7089 17.8007 63.6441 16.9237 59.232C16.0457 54.8199 16.4607 50.2483 18.1187 46.0664C19.7767 41.8845 22.6067 38.2703 26.2697 35.6581C29.9317 33.0459 34.2707 31.5468 38.7647 31.3409L38.0467 77.836C37.0837 77.7842 36.1327 77.6917 35.1897 77.5659C30.5617 76.6434 26.3267 74.3311 23.0477 70.938H23.0657ZM3.08266 40.3002C3.23266 30.7402 7.06866 21.6073 13.7897 14.8073C20.5107 8.00737 29.5987 4.06554 39.1567 3.80439L38.7867 28.2768C33.8427 28.4657 29.0507 30.0404 24.9587 32.8212C20.8657 35.6021 17.6377 39.4771 15.6417 44.0043C13.6447 48.5315 12.9617 53.5286 13.6687 58.4257C14.3747 63.3227 16.4437 67.9225 19.6397 71.7003C14.4717 68.2709 10.2477 63.5988 7.35466 58.1126C4.46166 52.6264 2.99266 46.5017 3.08266 40.3002ZM56.0797 71.4228C52.0517 75.3559 46.7287 77.687 41.1067 77.9803L41.7877 31.3928C46.2517 31.773 50.5117 33.4289 54.0607 36.1629C57.6097 38.8969 60.2967 42.5938 61.8037 46.8129C63.3097 51.0319 63.5707 55.5951 62.5557 59.9585C61.5417 64.3219 59.2927 68.3015 56.0797 71.4228Z"
      fill="white"
    />
  </Icon>
);
