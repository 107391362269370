import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import "../styles/gallery.css";
import { Link } from "gatsby";

// Components
import { ShortVideo } from "../videos/short-video";
import { VimeoThumbnail } from "../images/vimeo-thumbnail";
import { Caption } from "../text/caption";

const GalleryContainer = styled.div`
  position: relative;

  width: 100%;
  max-height: 100vh;
  overflow: hidden;

  padding: 0;

  border-left: 1px solid #1d1d1b;
  border-right: 1px solid #1d1d1b;

  & .carousel.carousel-slider,
  & .slider-wrapper,
  & .slider,
  & .slide,
  & video,
  & img {
    height: 100%;
    max-height: 100vh;
  }

  & .carousel.carousel-slider .control-arrow {
    padding: 0;
    width: 100px;
  }
`;

const VideoContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;
  border-bottom: 1px solid #1d1d1b;

  pointer-events: ${props => props.pointerEvents};

  & a {
    display: block;

    width: 100%;
    height: 100%;

    position: relative;
  }
`;

const VideoWrapper = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ${props => props.ratio}%;
  }
  & > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  & img {
    transition: 250ms opacity ease;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &:hover img {
    opacity: ${props => (props.fadeOutImage ? 0 : 1)};
  }
`;

export const Gallery = ({ data, location, videoLocation }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [allGalleryContent, setAllGalleryContent] = useState([]);
  const [activeSlide, setActiveSlide] = useState(1);
  const [totalSlides, setTotalSlides] = useState(null);
  const [activeSlideDescription, setActiveSlideDescription] = useState("");
  const [activeSlideTitle, setActiveSlideTitle] = useState("");
  const [allowPointerEvents, setAllowPointerEvents] = useState("none");
  const [onReady, setOnReady] = useState(false);

  const firstSlideId = data
    .filter(content => content !== null || content.video.document.data !== null)
    .filter((content, index) => index === 0)
    .map(content => content.video.document.id);

  const [currentVideoPlayerId, setCurrentVideoPlayerId] = useState(
    firstSlideId
  );
  const [shouldImageFadeOut, setImageFadeOut] = useState(false);

  useEffect(() => {
    setTotalSlides(data.length);
    setAllGalleryContent(data);

    if (data[0].video.document.data.brand.text !== "") {
      setActiveSlideDescription(data[0].video.document.data.brand.text);
    } else {
      setActiveSlideDescription(data[0].video.document.data.title.text);
    }

    if (data[0].video.document.data.director.uid !== null) {
      setActiveSlideTitle(
        data[0].video.document.data.director.document.data.title.text
      );
    } else {
      setActiveSlideTitle("");
    }
  }, []);

  useEffect(() => {
    setAllowPointerEvents(`all`);
  }, [onReady, setAllowPointerEvents]);

  const updateCurrentSlide = index => {
    if (currentSlide !== index) {
      setCurrentSlide(index);

      if (data[0].video.document.data.brand.text !== "") {
        setActiveSlideDescription(
          allGalleryContent[index].video.document.data.brand.text
        );
      } else {
        setActiveSlideDescription(
          allGalleryContent[index].video.document.data.title.text
        );
      }

      if (allGalleryContent[index].video.document.data.director.uid !== null) {
        setActiveSlideTitle(
          allGalleryContent[index].video.document.data.director.document.data
            .title.text
        );
      } else {
        setActiveSlideTitle("");
      }
    }
  };

  const galleryContent = data
    .filter(content => content !== null || content.video.document.data !== null)
    .map((content, index) => (
      <VideoContainer
        key={`director_video_${index}`}
        onMouseOver={() => setCurrentVideoPlayerId(content.video.document.id)}
        onMouseLeave={() => setCurrentVideoPlayerId("")}
        pointerEvents={allowPointerEvents}
      >
        <Link
          to={`/${content.video.document.uid}`}
          state={{
            location: location,
            videoLocation: videoLocation,
          }}
        >
          <VideoWrapper
            ratio={`56.25`}
            fadeOutImage={
              currentVideoPlayerId === content.video.document.id &&
              shouldImageFadeOut
            }
          >
            <div className="content">
              <ShortVideo
                video={content.video.document.data.vimeo_mp4_file.url}
                width={1920}
                height={1080}
                playerId={`all_directors_video_${index}`}
                prismicThumbnail={content.video.document.data.thumbnail}
                fadeOutImage={
                  currentVideoPlayerId === content.video.document.id &&
                  shouldImageFadeOut
                }
                setImageFadeOut={shouldImageFadeOut =>
                  setImageFadeOut(shouldImageFadeOut)
                }
                isPlaying={
                  currentVideoPlayerId === content.video.document.id
                    ? true
                    : false
                }
                setOnReady={setOnReady}
                loadedSeconds={1}
              />
              <VimeoThumbnail
                width={1920}
                height={1080}
                prismicThumbnail={content.video.document.data.thumbnail.fluid}
                embed_url={content.video.document.data.vimeo_video.embed_url}
              />
            </div>
          </VideoWrapper>
        </Link>
      </VideoContainer>
    ));

  return (
    <GalleryContainer>
      {galleryContent.length > 1 ? (
        <>
          <Carousel
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            infiniteLoop={true}
            useKeyboardArrows={true}
            className="video-gallery"
            selectedItem={currentSlide}
            onChange={index => updateCurrentSlide(index)}
            autoPlay={false}
            stopOnHover={false}
            interval={8000}
          >
            {galleryContent}
          </Carousel>
        </>
      ) : (
        <>{galleryContent}</>
      )}
      <Caption>
        <h2>{activeSlideDescription}</h2>
        <p>{activeSlideTitle}</p>
      </Caption>
    </GalleryContainer>
  );
};
